import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import * as React from 'react';

import Shell from '../components/Shell';

import './core.scss';
import './index.scss';

export default () => {
    return (
        <Shell>
            <div className="home">
                <div className="hero" />
                <div className="intro">
                    <div>
                        <h1>Hi!</h1>
                        <h1>
                            I’m Kathleen Strumila, a Melbourne based Science
                            Illustrator and Artist.
                        </h1>
                        <p>
                            Do you have a dull, empty space where you think some
                            unique vibrant art would be just the fix? Why wait
                            to commemorate the bold and beautiful people or pets
                            in your life?
                        </p>
                        <p>
                            I offer both one-of-a-kind custom portraits as well
                            as prints of unique artworks. I also offer art and
                            designs for promotional business material.
                        </p>
                        <p>
                            Whether it's for custom work, print or you just want
                            to say G’day please drop by and say hello. I’d love
                            to create something you can cherish for years to
                            come and help your ideas become a reality.
                        </p>
                    </div>
                </div>
                <div className="bubbles">
                    <div>
                        <Link to="/gallery">
                            <div className="pets cover" />
                            <h1>Gallery</h1>
                        </Link>
                    </div>
                    <div>
                        <Link to="/contact">
                            <div className="commission cover" />
                            <h1>Commissions</h1>
                        </Link>
                    </div>
                    <div>
                        <Link to="/about">
                            <div className="about cover" />
                            <h1>About Me</h1>
                        </Link>
                    </div>
                </div>
            </div>
        </Shell>
    );
};
